import React, { useState } from 'react'

const Content = () => {
  return (
      <div className="container mx-auto py-6">
        <h1 className="text-3xl text-primary mb-4">Impressum</h1>
        <div className="mb-4">
          <h1>Impressum</h1>
          <strong>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</strong>
          <br />
          JCC Group GmbH & Co. KG
          <br />
          Rheinpromenade 9<br />
          40789 Monheim am Rhein
          <br />
          E-Mail: vodafone@jcc.group
          <br />
          <br />
          Geschäftsführer:
          <br />
          Jochen Christoph
          <br />
          <br />
          Eintragung im Handelsregister:
          <br />
          Registergericht: Amtsgericht Dortmund
          <br />
          Registernummer: HRA 26020
          <br />
          <br />
          <strong>AGB</strong>
          <br />
          § 1. Allgemeines – Geltungsbereich
          <br />
          <br />
          <p>
            (1) Die nachstehenden Verkaufsbedingungen gelten für die gesamte über den Online-Shop
            unter der URL http://www.friyay.de begründeten Verträge zwischen der JCC Group GmbH & Co. KG
            und dem Kunden. Die JCC Group GmbH & Co. KG bietet auf der Website als gewerblicher Anbieter
            Waren aller Art, insbesondere aus dem Bereich Mobilfunk, Home Entertainment, Freizeit,
            Elektrogeräte und EDV zum Kauf an. Darüber hinaus vermittelt die JCC Group GmbH & Co. KG
            Mobilfunkverträge mit Mobilfunkanbietern.
          </p>
          <p>
            (2) Kunden können sowohl Verbraucher als auch Unternehmer sein. Verbraucher ist jede
            natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
            ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden
            können. Unternehmer ist jede natürliche oder juristische Person oder rechtsfähige
            Personengesellschaft, die bei Abschluss des Rechtsgeschäfts in Ausübung ihrer gewerblichen
            oder selbständigen beruflichen Tätigkeit handelt.
          </p>
          <p>
            (3) Wird aufgrund des Bestellvorgangs zwischen der JCC Group GmbH & Co. KG und dem Kunden keine
            Vertragsbeziehung begründet, sondern kommt diese durch die Vermittlung von der JCC Group GmbH &
            Co. KG zwischen dem Kunden und einer Drittfirma zustande, so bestimmen sich die Rechte und
            Pflichten des Kunden ausschließlich nach dem Vertrag, der zwischen dem Kunden und der von
            Drittfirma zustande kommt. Auf die Vertragsbedingungen dieser Drittfirmen hat die onAd
            GmbH & Co. KG keinen Einfluss. Ist ein Vertrag über die Lieferung von Waren, die von der
            JCC Group GmbH & Co. KG vertrieben werden, nachfolgend mit der Vermittlung eines
            Mobilfunkvertrages zwischen dem Kunden und einem Netzbetreiber verbunden, so ist das
            Zustandekommen des erstgenannten Vertrages aufschiebend bedingt und abhängig von dem
            Zustandekommen des Vertrages zwischen dem Kunden und dem Mobilfunkanbieter.
          </p>
          <br />
          § 2. Preise, Zahlungsbedingungen
          <br />
          <br />
          <p>
            (1) Die als (Kauf-) Preise angeführten Beträge verstehen sich ausnahmslos als Endpreise
            und beinhalten sämtliche Preisbestandteile einschließlich anfallender Umsatzsteuern. Im
            Einzelfall können jedoch bei grenzüberschreitenden Lieferungen Steuern (z.B. im Fall eines
            innergemeinschaftlichen Erwerbs) und/oder Abgaben (z.B. Zölle) anfallen, die vom Kunden zu
            zahlen sind. Weiterführende Informationen finden sich im Internet unter:
            http://ec.europa.eu/taxation_customs/dds/cgi-bin/tarchap?Lang=DE
            http://auskunft.ezt-online.de/ezto/Welcome.do
            http://xtares.admin.ch/tares/login/loginFormFiller.do
          </p>
          <p>
            (2) Die Kaufpreise gelten – soweit nicht ausdrücklich etwas anderes zwischen der JCC Group GmbH
            & Co. KG und dem Kunden vereinbart ist – ohne Fracht, Porto und Versicherung. Bei der
            Versendung fallen daher zusätzliche Liefer- und Versandkosten an; die Höhe der
            zusätzlichen Liefer- und Versandkosten richtet sich nach den im Zusammenhang mit dem
            konkreten Angebot gemachten Angaben.
          </p>
          <p>
            (3) JCC Group GmbH & Co. KG teilt dem Kunden im Zusammenhang mit dem Artikelangebot jeweils
            mit, welche Zahlungsmöglichkeiten konkret zur Verfügung stehen. Der Kunde wählt unter den
            zur Verfügung stehenden Zahlungsmethoden die bevorzugte Zahlungsart eigenständig aus.
          </p>
          <p>
            (4) Der Kunde verpflichtet sich im Fall einer vereinbarten Vorausüberweisung /
            Banküberweisung / PayPal den Kaufpreis zzgl. anfallender Versandkosten spätestens fünf
            Tage nach Erhalt der Zahlungsaufforderung ohne Abzug zu zahlen. Nach erfolglosem Ablauf
            der Frist kommt kein Kauf zustande und der Kunde hat kein Anrecht mehr auf seine
            Bestellung.
          </p>
          <p>
            (5) Die Konto- bzw. Überweisungsdaten teilt die JCC Group GmbH & Co. KG dem Kunden zusammen mit
            der Zahlungsaufforderung mit.
          </p>
          <br />
          B. Kaufverträge über Waren
          <br />
          <br />
          § 1. Zustandekommen des Vertrages; Vertragssprache; Vertragstextspeicherung
          <br />
          <br />
          <p>
            (1) Zwischen der JCC Group GmbH & Co. KG und dem Kunden werden über den Onlineshop Kaufverträge
            über die angebotenen Artikel geschlossen. Die innerhalb des Onlineshops dargestellten
            Produkte stellen keine die JCC Group GmbH & Co. KG bindenden Angebote dar; es handelt sich
            vielmehr um die Aufforderung an Kunden, ein verbindliches Angebot durch Abgabe einer
            Bestellung zu unterbreiten.
          </p>
          <p>
            (2) Der Kunde hat die Möglichkeit, innerhalb des Onlineshops Produkte auszuwählen und zu
            bestellen. Vor Absendung der Bestellung ermöglicht die JCC Group GmbH & Co. KG dem Kunden, die
            Bestelldaten zu überprüfen und etwaige Eingabefehler zu berichtigen. Durch Absendung der
            Bestellung aus dem virtuellen „Warenkorb“ gibt der Kunde eine Bestellung über die in
            diesem enthaltenen Artikel ab. Den Zugang der Bestellung wird die JCC Group GmbH & Co. KG dem
            Kunden gegenüber unverzüglich bestätigen.
          </p>
          <p>
            (3) Die JCC Group GmbH & Co. KG ist berechtigt, das Vertragsangebot des Kunden innerhalb von
            zwei Tagen nach Zugang der Bestellung anzunehmen oder Abzulehnen. Der Kaufvertrag kommt
            mit der Annahme der Bestellung durch die JCC Group GmbH & Co. KG zustande. Die JCC Group GmbH & Co.
            KG kann die Bestellung durch Versand einer Auftragsbestätigung per E-Mail nach
            Freischaltung des Antrages durch den Mobilfunkprovider, bzw. Netzbetreiber, oder durch
            Auslieferung der Ware annehmen.
          </p>
          <p>(4) Die Vertragssprache ist deutsch.</p>
          <p>(5) Der Vertragstext wird durch JCC Group GmbH & Co. KG gespeichert.</p>
          <br />
          § 2. Eigentumsvorbehalt
          <br />
          <br />
          <p>
            (1) Die JCC Group GmbH & Co. KG behält sich das Eigentum an der Ware bis zur vollständigen
            Zahlung des Kaufpreises sowie Bestätigung der Vermittlung des Mobilfunkvertrages vor. Der
            Kunde ist verpflichtet, die Ware während des Bestehens des Eigentumsvorbehalts pfleglich
            zu behandeln.
          </p>
          <p>
            (2) Der Kunde unterrichtet die JCC Group GmbH & Co. KG unverzüglich schriftlich von allen
            Zugriffen Dritter auf die Ware, insbesondere von Zwangsvollstreckungsmaßnahmen, sowie von
            etwaigen Beschädigungen oder der Vernichtung der Ware. Einen Besitzwechsel der Ware sowie
            den eigenen Anschriftenwechsel zeigt der Kunde der JCC Group GmbH & Co. KG unverzüglich an.
          </p>
          <p>
            (3) Die JCC Group GmbH & Co. KG ist berechtigt, bei vertragswidrigem Verhalten des Kunden,
            insbesondere bei Zahlungsverzug, wenn der Kunde seiner Zahlungsverpflichtung trotz einer
            Mahnung der JCC Group GmbH & Co. KG nicht nachkommt, nach einer vorherigen angemessenen
            Fristsetzung vom Vertrag zurückzutreten und die Ware herauszuverlangen. Daneben ist die
            JCC Group GmbH & Co. KG berechtigt, bei Verletzung einer Pflicht nach A. § 2 Abs. 2 vom Vertrag
            zurückzutreten und die Ware herauszuverlangen, wenn der JCC Group GmbH & Co. KG ein Festhalten
            am Vertrag nicht mehr zuzumuten ist.
          </p>
          <p>
            (4) Der Kunde ist in seiner Eigenschaft als Unternehmer berechtigt, die Ware im
            ordentlichen Geschäftsgang weiterzuveräußern. Er tritt der JCC Group GmbH & Co. KG bereits
            jetzt alle Forderungen in Höhe des Rechnungsbetrages ab, die ihm durch die
            Weiterveräußerung gegen einen Dritten erwachsen. Die JCC Group GmbH & Co. KG nimmt die
            Abtretung an. Nach der Abtretung ist der Unternehmer zur Einziehung der Forderung
            ermächtigt. Die JCC Group GmbH & Co. KG behält sich vor, die Forderung selbst einzuziehen,
            sobald der Unternehmer seiner Zahlungsverpflichtungen nicht ordnungsgemäß nachkommt und in
            Zahlungsverzug gerät.
          </p>
          <br />
          <strong>Folgen des Widerrufs</strong>
          <br />
          <br />
          <p>
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
            erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
            sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
            günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn
            Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags
            bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
            Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
            ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
            Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren
            wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
            zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
          </p>
          <p>
            Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem
            Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden
            oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von
            vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
          </p>
          <p>
            Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust
            auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht
            notwendigen Umgang mit ihnen zurückzuführen ist.
          </p>
          <strong>Vorzeitiges Erlöschen des Widerrufsrechts</strong>
          <br />
          <br />
          <p>
            Das Widerrufsrecht erlischt vorzeitig nach § 312g Abs. 2 Nr. 6 BGB bei Verträgen zur
            Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten
            Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.
          </p>
          <p>
            Muster-Widerrufsformular nach Anlage 2 zu Art. 246a § 1 Abs. 2 S. 1 Nr. 1 u. § 2 Abs. 2
            Nr. 2 EGBGB (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular
            aus und senden Sie es zurück.) – CC Group GmbH & Co. KG, Rheinpromenade 9 , D-40789 Monheim am Rhein;
            E-Mail:vodafone@jcc.group – Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
            abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden
            Dienstleistung (*) – Bestellt am (*)/erhalten am (*) – Name des/der Verbraucher(s) –
            Anschrift des/der Verbraucher(s) – Unterschrift des/der Verbraucher(s) (nur bei Mitteilung
            auf Papier) – Datum ______ (*) Unzutreffendes streichen
          </p>
          <strong>Folgen des Widerrufs eines vermittelten Vertrages</strong>
          <p>
            Vodafone: Hast Du online oder telefonisch einen Kaufvertrag über ein Mobiltelefon oder
            Zubehör bei der Vodafone GmbH abgeschlossen? Oder bei uns einen Service gebucht, wie z.B.
            einen Vodafone-Tarif? Dann bist Du bei unserem Retouren-Portal auf www.vodafone.de/retoure
            richtig. Dort kannst Du einfach online Deinen Vertrag widerrufen oder einen kostenlosen
            Rücksendeschein ausdrucken, wenn Du uns ein Gerät zurückschicken willst. Im InfoDok 220
            kannst Du außerdem alle Infos zum Widerrufsrecht bei uns nachlesen.
            https://www.vodafone.de/infofaxe/220.pdf
          </p>
          <p>
            Otelo: Wenn Sie online über unsere Homepage oder telefonisch über unsere Bestell-Hotline
            einen otelo Vertrag abgeschlossen haben, dann können Sie den Vertrag innerhalb von 14
            Tagen ab Lieferung ohne Begründung widerrufen. Widerruf eines Laufzeit-Vertrages: Widerruf
            eines Laufzeit-Vertrages oder einer Prepaid-Bestellung ohne Endgerät: Sie können für Ihren
            Widerruf unser Musterwiderrufsformular verwenden oder eine entsprechende Erklärung in
            einer beliebigen anderen Form abgeben. Die zugeschickte otelo SIM-Karte benötigen wir
            nicht zurück und kann entsorgt werden. Bitte richten Sie Ihren Widerruf über einen der
            folgenden Kanäle an uns: Schriftlich an otelo-Team, c/o Vodafone, 40875 Ratingen Per Fax
            an 02102 98 65 75 Per E-Mail an kontakt@otelo.de
          </p>
          <br />
          § 4. Liefer- und Versandbedingungen, Gefahrübergang
          <br />
          <br />
          <p>
            (1) Die JCC Group GmbH & Co. KG wird die Lieferung der gekauften Artikel – sofern nichts
            anderes mit dem Kunden vereinbart ist – auf dem Versandwege an die vom Kunden hinterlegte
            Lieferanschrift vornehmen. Eine Selbstabholung ist ausgeschlossen.
          </p>
          <p>
            (2) Der Versand der vom Kunden gekauften Artikel erfolgt im Fall einer Zahlung durch
            Banküberweisung oder via PayPal oder mittels Kreditkarte nach einer Bestätigung Ihres
            vermittelten Mobilfunkvertrages. Dies kann bis zu vierzehn Tage dauern. Versichern Sie
            vorher mit einer Bestätigungsmail, Foto etc. glaubhaft den Vertrag zu benutzen wird das
            Handy vorher versendet. Die Lieferfrist, innerhalb derer das Transportunternehmen die
            Waren ausliefert, beträgt somit 14-20 Werktage nach Zahlungseingang, es sei denn, im
            Zusammenhang mit dem Artikelangebot wird auf eine kürzere oder längere Frist ausdrücklich
            hingewiesen.
          </p>
          <p>
            (3) Bei Verträgen mit Verbrauchern geht die Gefahr des zufälligen Untergangs und der
            zufälligen Verschlechterung der verkauften Ware immer – auch beim Versendungskauf – mit
            der Übergabe der Ware an den Verbraucher auf diesen über. Bei Unternehmern geht die Gefahr
            des zufälligen Untergangs und der zufälligen Verschlechterung der Ware mit der Übergabe an
            diese selbst oder eine empfangsberechtigte Person, beim Versendungskauf hingegen mit der
            Auslieferung der Ware an eine geeignete Transportperson über. Der Übergabe steht es
            gleich, wenn der Kunde mit der Annahme in Verzug ist.
          </p>
          § 5. Gewährleistungsrechte bei Warenlieferungen
          <br />
          <br />
          <p>
            Kunden stehen beim Kauf von Waren gesetzliche Mängelhaftungsrechte für die Waren zu. Als
            Kunde haben Sie deshalb bei Mängeln der Sache das Recht, Gewährleistungsrechte unter
            Beachtung der nachstehenden Regelungen geltend zu machen.
          </p>
          <p>
            (1) Keine Gewährleistung besteht bei solchen Schäden, die infolge einer unsachgemäßen
            Verwendung oder Behandlung des Artikels durch den Kunden entstanden sind. (2) Ist die
            Kaufsache mangelhaft, hat der Kunde, wenn und soweit die Voraussetzungen der gesetzlichen
            Vorschriften zu den Rechten bei Mängeln vorliegen, einen Anspruch auf Nacherfüllung nach
            §§ 437 Nr. 1, 439 BGB.
          </p>
          <p>
            (3) Verbraucher haben insoweit die Wahl, ob Nacherfüllung durch Nachbesserung oder
            Ersatzlieferung erfolgen soll. JCC Group GmbH & Co. KG bleibt jedoch berechtigt, die Art der
            gewählten Nacherfüllung zu verweigern, wenn sie nur mit unverhältnismäßigen Kosten möglich
            ist und die andere Art der Nacherfüllung ohne erhebliche Nachteile für den Verbraucher
            bleibt.
          </p>
          <p>
            (4) Gegenüber Unternehmern leistet die JCC Group GmbH & Co. KG für Mängel der Ware zunächst
            nach eigener Wahl Gewähr durch Nachbesserung oder Ersatzlieferung.
          </p>
          <p>
            (5) Ansprüche und Rechte eines Unternehmers wegen Mängeln an einer neuen Sache verjähren
            ein Jahr nach Ablieferung der Ware beim Unternehmer; Ansprüche und Rechte eines
            Verbrauchers wegen Mängeln an einer neuen Sache verjähren zwei Jahre nach Ablieferung der
            Ware beim Verbraucher. Im Fall von gebrauchten Sachen beträgt die Verjährungsfrist für
            Ansprüche und Rechte des Kunden wegen Mängeln an dieser gebrauchten Sache ein Jahr ab
            Ablieferung der Ware beim Kunden. Mit diesen Fristen verbundene Verjährungserleichterungen
            gelten nicht, soweit die JCC Group GmbH & Co. KG nach Ziff. D. § 1 dieser AGB haftet oder es um
            das dingliche Recht eines Dritten geht, aufgrund dessen die Herausgabe des
            Liefergegenstandes verlangt werden kann.
          </p>
          <p>
            (6) Die in §§ 478, 479 BGB bestimmten Rückgriffsansprüche von Unternehmern bleiben von den
            Regelungen der vorstehenden Absätze unberührt.
          </p>
          <p>
            (7) Offensichtliche Mängel sind durch Kaufleute innerhalb einer Frist von zwei Wochen ab
            Empfang der Ware über obig aufgeführte Kontaktdaten anzuzeigen; ansonsten ist die
            Geltendmachung des Gewährleistungsanspruchs ausgeschlossen. Zur Wahrung der Frist genügt
            die rechtzeitige Absendung der Mangelanzeige.
          </p>
          <strong>Datenschutz</strong>
          <br />
          <br />
          3.5 Friyay.de Onlineauftritt, Webseitenoptimierung
          <br />
          <br />
          3.5.1 Cookies - Allgemeine Informationen
          <br />
          <p>
            Wir setzen auf unserer Webseite Cookies ein, um den Besuch unserer Website attraktiv zu
            gestalten und die Nutzung bestimmter Funktionen zu ermöglichen sowie die Nutzung unserer
            Website statistisch zu erfassen. Hierbei handelt es sich um kleine Dateien, die Ihr
            Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone, o.ä.)
            gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät
            keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem
            Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch
            eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar
            Kenntnis von Ihrer Identität erhalten.
          </p>
          <p>
            Die meisten der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung wieder
            gelöscht (sog. Sitzungs- bzw. Sessioncookies). Darüber hinaus setzen wir ebenfalls zur
            Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
            festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite
            erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie
            bereits bei uns waren und welche Eingaben und Einstellungen Sie getätigt haben, um diese
            nicht noch einmal eingeben zu müssen. Die durch Cookies verarbeiteten Daten sind für die
            genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6
            Abs. 1 S. 1 lit. f DSGVO erforderlich.
          </p>
          <p>
            Selbstverständlich können Sie Ihren Browser so einrichten, dass er unsere Cookies nicht
            auf Ihrem Endgerät ablegt. Die Hilfe-Funktion in der Menüleiste der meisten Webbrowser
            erklärt Ihnen, wie Sie Ihren Browser davon abhalten können, neue Cookies zu akzeptieren,
            wie Sie Ihr Browser darauf hinweist, wenn Sie neue Cookie erhalten oder auch wie Sie
            sämtliche bereits erhaltenen Cookies löschen und für alle weiteren sperren können. Sofern
            es sich bei diesen Cookies und/oder darin enthaltenen Informationen um personenbezogene
            Daten handelt, ist Rechtsgrundlage der Datenverarbeitung Art. 6 Absatz 1 Buchstabe f)
            DSGVO. Unser Interesse, unsere Webseite zu optimieren, ist dabei als berechtigt im Sinne
            der vorgenannten Vorschrift anzusehen.
          </p>
          <br />
          3.5.2 Google Analytics
          <br />
          <p>
            Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Webseite
            nutzen wir auf Grundlage des Artikel 6 Absatz 1 Buchstabe f) DSGVO Google Analytics, einen
            Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
            der Website durch Sie ermöglichen. In diesem Zusammenhang werden pseudonymisierte
            Nutzungsprofile erstellt und Cookies verwendet. Die durch das Cookie erzeugten
            Informationen über Ihre Benutzung dieser Website wie Browser-Typ/-Version, verwendetes
            Betriebssystem, Referrer-URL (die zuvor besuchte Seite), Hostname des zugreifenden
            Rechners (IP-Adresse) und Uhrzeit der Serveranfrage.
          </p>
          <p>
            Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um
            Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
            Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
            Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie
            in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
            nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und
            auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
            verfügbare Browser-Plugin herunterladen und installieren:
            tools.google.com/dlpage/gaoptout?hl=de.
          </p>
          <p>
            Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können
            Sie die Erfassung durch Google Analytics zudem verhindern. Es wird ein Opt-Out-Cookie
            gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert.
            Das Opt-Out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf
            Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das
            Opt-Out-Cookie erneut setzen. Weitere Informationen zum Datenschutz im Zusammenhang mit
            Google Analytics finden Sie auf der Webseite von Google Analytics.
          </p>
          <br />
          3.5.3 Google Adwords
          <br />
          <p>
            Unsere Website nutzt den Dienst Google Adwords. Google AdWords ist ein
            Online-Werbeprogramm von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
            USA (“Google”). Dabei verwenden wir zum einen die Remarketing-Funktion innerhalb des
            Google AdWords-Dienstes. Mit der Remarketing-Funktion können wir Nutzern unserer Website
            auf anderen Websites innerhalb des Google Displaynetzwerks (auf Google selbst, sog.
            „Google Anzeigen“ oder auf anderen Websites) auf deren Interessen basierende Werbeanzeigen
            präsentieren. Dafür wird die Interaktion der Nutzer auf unserer Website analysiert, z.B.
            für welche Angebote sich der Nutzer interessiert hat, um den Nutzern auch nach dem Besuch
            unserer Website auf anderen Seiten zielgerichtete Werbung anzeigen zu können. Hierfür
            speichert Google eine Zahl in den Browsern von Nutzern, die bestimmte Google-Dienste oder
            Websites im Google Displaynetzwerk besuchen. Über diese als “Cookie” bezeichnete Zahl
            werden die Besuche dieser Nutzer erfasst. Diese Zahl dient zur eindeutigen Identifikation
            eines Webbrowsers auf einem bestimmten Computer und nicht zur Identifikation einer Person,
            personenbezogene Daten werden nicht gespeichert. Rechtsgrundlage für diese
            Datenverarbeitung ist Artikel 6 Absatz 1 Buchstabe f) DSGVO.
          </p>
          <p>
            Sie können die Verwendung von Cookies durch Google deaktivieren, indem Sie dem
            nachfolgenden Link folgen und das dort bereitgestellte Plug-In herunterladen und
            installieren: www.google.com/settings/ads/plugin.
          </p>
          <p>
            Nähere Informationen zu Google Remarketing sowie die Datenschutzerklärung von Google
            können Sie einsehen unter: www.google.com/privacy/ads/.
          </p>
          3.5.4 Google Conversion Tracking
          <br />
          <p>
            Ferner verwenden wir im Rahmen der Nutzung des Google AdWords-Dienstes das sog. Conversion
            Tracking. Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für
            das Conversion-Tracking auf Ihrem Rechner/Endgerät abgelegt. Diese Cookies verlieren nach
            30 Tagen ihre Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht
            der persönlichen Identifizierung. Die Informationen, die mithilfe des Conversion-Cookie
            eingeholten werden, dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen,
            die sich für Conversion-Tracking entschieden haben.
          </p>
          3.5.5 Widerspruchs-/Opt-Out-Möglichkeit
          <br />
          <p>
            Neben den bereits beschriebenen Deaktivierungsmethoden können Sie die erläuterten
            Technologien auch ganz allgemein durch eine entsprechende Cookie-Einstellung in Ihrem
            Browser unterbinden. Daneben haben Sie die Möglichkeit, präferenzbasierte Werbung mit
            Hilfe des hier abrufbaren Präferenzmanagers zu deaktivieren.
          </p>
        </div>
      </div>
  )
}

export default Content
